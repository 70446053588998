// src/hooks/useCheckConfigVersion.jsx

import { useEffect, useRef } from "react";
import { getConfigFromLocal } from "../utils/helpers";
import { getConfigVersion } from "../utils/api";

const useCheckConfigVersion = (
  appCode,
  appID,
  language,
  sessionID,
  localConfigVersion,
  linkState,
  onConfigUpdate,
  onConfigMatch,
  configVersionCheckedTime, // Nouă prop
  setConfigVersionCheckedTime // Nouă funcție pentru actualizare
) => {
  const intervalRef = useRef(null);

  const TEN_MINUTES = 10 * 60 * 1000; // 10 minute în milisecunde

  const checkAndUpdateConfig = async () => {
    console.log("useCheckConfigVersion =======>   appCode", appCode);

    if (!appID || !sessionID || !appCode || appCode === "noAppCode") return;

    const now = Date.now();
    const lastChecked = configVersionCheckedTime ? new Date(configVersionCheckedTime).getTime() : 0;

    if (now - lastChecked < TEN_MINUTES) {
      // Nu au trecut 10 minute, încarcă configurația locală
      console.log("Less than 10 minutes since last check. Loading local config.");
      const localConfig = getConfigFromLocal(appCode, language);
      if (localConfig) {
        onConfigMatch(localConfig);
      } else {
        // Dacă nu există configurație locală, descarcă una nouă
        onConfigUpdate(appCode, localConfigVersion);
      }
      return;
    }

    try {
      const serverConfigVersion = await getConfigVersion(appID, sessionID);
      if (serverConfigVersion && serverConfigVersion !== localConfigVersion) {
        // Versiunile diferă, actualizează configurația
        onConfigUpdate(appCode, serverConfigVersion);
        // Actualizează timpul ultimei verificări
        setConfigVersionCheckedTime(now);
      } else {
        // Versiunile se potrivesc, încarcă configurația locală
        const localConfig = getConfigFromLocal(appCode, language);
        if (localConfig) {
          onConfigMatch(localConfig);
        } else {
          // Dacă nu există configurație locală, descarcă una nouă
          onConfigUpdate(appCode, serverConfigVersion);
          // Actualizează timpul ultimei verificări
          setConfigVersionCheckedTime(now);
        }
      }
    } catch (error) {
      console.error("Error checking config version:", error);
    }
  };

  useEffect(() => {
    if (!appID || !sessionID || !appCode || appCode === "noAppCode") return;

    // Verificare inițială
    checkAndUpdateConfig();

    // Setează un interval pentru verificări periodice
    intervalRef.current = setInterval(() => {
      checkAndUpdateConfig();
    }, TEN_MINUTES); // 10 minute

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [appCode, appID, sessionID, localConfigVersion, language, configVersionCheckedTime]);

  return null;
};

export default useCheckConfigVersion;
