// src/context/AppContext.jsx
import React, {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { initializeSession, downloadNewConfig } from "../utils/api";
import { v4 as uuidv4 } from "uuid";
import useCheckConfigVersion from "../hooks/useCheckConfigVersion";
// import { buildSite, buildPortalSite } from '../utils/buildSite';
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  setConfigToLocal,
  getConfigFromLocal,
  getColorFromTheme,
  isIP,
  deriveProductsFromConfig,
  updateCartAfterNewConfig,
  extractParam,
  generatePathFromLinkState,
} from "../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { extendTheme, ChakraProvider, Box } from "@chakra-ui/react"; // Importăm ChakraProvider
import { shade, tint } from "polished";
import defaultTheme from "../theme"; // Importăm tema din src/theme/index.js
// import useUpdateConfig from "../hooks/useUpdateConfig"; // Import the custom hook
// import { useTranslation } from "react-i18next";
// import i18n from "../i18n/i18n";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../components/Loading/LoadingIndicator";

const initialState = {
  sessionID: null,

  linkState: {
    route: "", // sau 'store' 'portal'....
    appCode: "noAppCode",
    language: "en",
    page: "menu", // sau 'about', 'home', 'gallery' ...
    search: null,
    category: null,
    product: null,
    appDomain: "",
  },

  appInfo: null,

  user: null,

  portal: {},
  favorites: [],
  showFavoritesOnly: false,
  showCategoriesOnly: true,
  listDisplayMode: "grid", // sau "rightImageList" leftImageList
  jumpToCategory: null,
  currentCategory: null,
  selectedAllergens: [], // Stare pentru alergeni selectați
  selectedTags: [],

  config: {}, // Configurația parsată
  configXML: null, // Configurația XML brută, dacă este necesar
  configVersion: "0.0.0",
  configVersionCheckedTime: null, // Adăugăm configVersionCheckedTime

  menuList: [],
  configOBJ: {
    AppLanguages: {},
    ECommerce: {},
    MenuList: [],
    ProductsModules: {},
    VisibleGroupings: {},
    ModifiersGroups: {},
    ModifiersGroupsByParent: {},
    Products: {},
    Extras: {},
    CustomFeatures: {},
    Groupings: {},
    GroupsComboOffer: {},
    Places: {},
    theme: {
      primaryColor: "teal.500",
      buttonColor: "teal",
      fonts: {
        heading: "Roboto, sans-serif",
        body: "Open Sans, sans-serif",
      },
      backgrounds: {
        pageBackground: "gray.50",
        productBoxBackground: "white",
        cartBackground: "gray.100",
        loginModalBackground: "white",
        accountModalBackground: "white",
        mobileFooterBackground: "white",
        footer: "gray.600", // Valoare implicită pentru footer
        header: "white", // Valoare implicită pentru header
        mainPage: "gray.50", // Valoare implicită pentru mainpage
      },
      textColors: {
        productBox: "black",
        cart: "black",
        header: "black",
        footer: "black",
        mobileFooter: "black",
        mainPage: "black",
        CartModal: "black",
        UserModal: "black",
      },
    },
  },
  customTheme: defaultTheme, // Initialize with the default theme
  cart: [],
  // isLoadingSession: true, // Pentru inițializarea sesiunii
  // isLoadingShop: false, // Pentru încărcarea magazinului
  // isLoadingPortal: false, // Pentru încărcarea portalului
  isCartModalOpen: false, // Pentru vizibilitatea modalului coș
  error: null,

  isLoadingInitializeSession: false,
  isLoadingGetAppInfo: false,
  isLoadingGetPortalInfo: false,
  isLoadingGetConfigVersion: false,
  isLoadingDownloadNewConfig: false,
};

const AppContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LINK_STATE":
      return {
        ...state,
        linkState: { ...state.linkState, ...action.payload },
      };

    // case "SET_LINK_STATE":
    //   return {
    //     ...state,
    //     linkState: {
    //       language: action.payload.language,
    //       page: action.payload.page,
    //     },
    //   };

    case "SET_LINK_STATE2":
      return {
        ...state,
        linkState: {
          language: action.payload.language,
          page: action.payload.page,
        },
      };

    case "SET_APP_CODE":
      return {
        ...state,
        linkState: { ...state.linkState, appCode: action.payload },
      };
    case "SET_APP_DOMAIN":
      return {
        ...state,
        linkState: { ...state.linkState, appDomain: action.payload },
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        linkState: { ...state.linkState, language: action.payload },
      };

    case "SET_SESSION_ID":
      return { ...state, sessionID: action.payload };
    case "SET_APP_INFO":
      return { ...state, appInfo: action.payload };

    case "SET_CONFIG":
      return { ...state, config: action.payload };
    case "SET_PORTAL":
      return { ...state, portal: action.payload };
    case "FILTER_BY_CATEGORY":
      // Implementarea filtrării după categorie
      return { ...state, selectedCategory: action.payload };

    case "SET_CONFIG_VERSION":
      return { ...state, configVersion: action.payload };

    case "SET_CONFIG_VERSION_CHECKED_TIME":
      return {
        ...state,
        configVersionCheckedTime: action.payload,
      };

    case "SET_CONFIG_OBJ":
      return {
        ...state,
        configOBJ: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        configOBJ: {
          ...state.configOBJ,
          theme: {
            ...state.configOBJ.theme,
            ...action.payload, // Suprascrie doar proprietățile noi
            backgrounds: {
              ...state.configOBJ.theme.backgrounds,
              ...action.payload.backgrounds, // Suprascrie doar background-urile noi
            },
            textColors: {
              ...state.configOBJ.theme.textColors,
              ...action.payload.textColors, // Suprascrie doar culorile textului noi
            },
          },
        },
      };
    case "ADD_FAVORITE":
      return {
        ...state,
        favorites: [...state.favorites, action.payload.id],
      };
    case "REMOVE_FAVORITE":
      return {
        ...state,
        favorites: state.favorites.filter((id) => id !== action.payload.id),
      };
    case "SET_FAVORITES":
      return {
        ...state,
        favorites: action.payload,
      };

    case "OPEN_CART_MODAL":
      // Poți folosi un state boolean pentru a controla vizibilitatea modalului coș
      return {
        ...state,
        isCartModalOpen: true,
      };

    case "CLOSE_CART_MODAL":
      return {
        ...state,
        isCartModalOpen: false,
      };
    case "SET_DISPLAY_MODE":
      return { ...state, listDisplayMode: action.payload };

    // Gestionarea vizualizării categoriilor și favoritelelor
    case "SET_SHOW_CATEGORIES_ONLY":
      return {
        ...state,
        showCategoriesOnly: action.payload,
      };
    case "SET_SHOW_FAVORITES_ONLY":
      return {
        ...state,
        showFavoritesOnly: action.payload,
      };

    case "SET_JUMP_TO_CATEGORY":
      return {
        ...state,
        jumpToCategory: action.payload,
      };

    case "SET_CURRENT_CATEGORY":
      if (state.currentCategory === action.payload) {
        return state; // Nu face nimic dacă categoria este deja selectată
      }
      return { ...state, currentCategory: action.payload };

    // case "SET_BACKGROUND":
    //   return {
    //     ...state,
    //     configOBJ: {
    //       ...state.configOBJ,
    //       theme: {
    //         ...state.configOBJ.theme,
    //         backgrounds: action.payload,
    //       },
    //     },
    //   };

    // Gestionarea adăugării în coș
    case "ADD_TO_CART":
      const existingItem = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.id === action.payload.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }],
        };
      }

    // Gestionarea eliminării din coș
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };

    // Resetarea coșului
    case "RESET_CART":
      return { ...state, cart: [] };
    case "UPDATE_CART_ITEM":
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };

    case "UPDATE_CART_AFTER_CONFIG":
      const updatedCart = updateCartAfterNewConfig(state.cart, state.configOBJ);
      return { ...state, cart: updatedCart };
    // case "SET_LOADING_SESSION":
    //   return { ...state, isLoadingSession: action.payload };
    // case "SET_LOADING_SHOP":
    //   return { ...state, isLoadingShop: action.payload };
    // case "SET_LOADING_PORTAL":
    //   return { ...state, isLoadingPortal: action.payload };

    case "SET_SELECTED_ALLERGENS":
      return { ...state, selectedAllergens: action.payload };
    case "SET_SELECTED_TAGS":
      return { ...state, selectedTags: action.payload };

    // case "SET_APP_INFO":
    //   return { ...state, appInfo: { ...state.appInfo, ...action.payload } };
    case "SET_CUSTOM_THEME":
      return { ...state, customTheme: action.payload };

    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoadingInitializeSession: false,
        isLoadingGetAppInfo: false,
        isLoadingGetPortalInfo: false,
        isLoadingGetConfigVersion: false,
        isLoadingDownloadNewConfig: false,
      };
    case "CLEAR_ERROR":
      return { ...state, error: null };

    case "SET_LOADING_INITIALIZE_SESSION":
      return { ...state, isLoadingInitializeSession: action.payload };
    case "SET_LOADING_GET_APP_INFO":
      return { ...state, isLoadingGetAppInfo: action.payload };
    case "SET_LOADING_GET_CONFIG_VERSION":
      return { ...state, isLoadingGetConfigVersion: action.payload };
    case "SET_LOADING_DOWNLOAD_NEW_CONFIG":
      return { ...state, isLoadingDownloadNewConfig: action.payload };
    case "SET_LOADING_GET_PORTAL_INFO":
      return { ...state, isLoadingGetPortalInfo: action.payload };

    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const hasReplacedPathRef = useRef(false);
  const { i18n } = useTranslation();
  // const params = useParams();
  const location = useLocation();
  const navigate = useNavigate(); // Inițializează navigate
  const linkState = state.linkState;

  // const location = window.location;

  const [customTheme, setCustomTheme] = useState(defaultTheme); // Definim starea pentru tema personalizată
  const [isReady, setIsReady] = useState(false); // Adăugăm isReady

  // const updateConfig = useUpdateConfig(); // Use the custom hook

  const doApplyConfig = (newConfig) => {
    console.log("doApplyConfig call =>> state.appInfo:", state.appInfo);
    if (!state.appInfo) return;

    console.log("doApplyConfig enter with  =>> state.appInfo:", state.appInfo);
    // Derive config object from the new configuration
    const configObj = deriveProductsFromConfig(newConfig, state.appInfo);

    dispatch({ type: "SET_CONFIG_OBJ", payload: configObj });

    // Update the theme based on the new config
    if (
      typeof configObj.theme !== "undefined" &&
      Object.keys(configObj.theme).length > 0
    ) {
      const themeSettings = {};
      if (typeof configObj.theme.primaryColor !== "undefined") {
        themeSettings.primaryColor = configObj.theme.primaryColor;
      }

      if (typeof configObj.theme.buttonColor !== "undefined") {
        themeSettings.buttonColor = configObj.theme.buttonColor;
      }
      if (typeof configObj.theme.fonts !== "undefined") {
        themeSettings.fonts = configObj.theme.fonts;
      }

      if (typeof configObj.theme.backgrounds !== "undefined") {
        themeSettings.backgrounds = configObj.theme.backgrounds;
      }
      if (typeof configObj.theme.textColors !== "undefined") {
        themeSettings.textColors = configObj.theme.textColors;
      }

      dispatch({ type: "SET_THEME", payload: themeSettings });

      // Generate the updated theme
      // const primaryColor = getColorFromTheme(themeSettings.primaryColor);

      // Actualizează tema personalizată
      const primaryColor = getColorFromTheme(
        themeSettings.primaryColor,
        defaultTheme
      );

      const updatedTheme = extendTheme({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary: primaryColor,
          primaryDark: shade(0.2, primaryColor),
          primaryLight: tint(0.2, primaryColor),
          button: themeSettings.buttonColor,
          // ... alte culori
        },
        fonts: themeSettings.fonts,
        styles: {
          global: (props) => ({
            body: {
              bg:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentBg.mainPage.light
                  : defaultTheme.colors.componentBg.mainPage.dark,
              color:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentText.mainPage.light
                  : defaultTheme.colors.componentText.mainPage.dark,
            },
          }),
        },
        components: {
          Box: {
            variants: {
              productBox: (props) => ({
                bg:
                  themeSettings.backgrounds.productBoxBackground ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.productBox.light
                    : defaultTheme.colors.componentBg.productBox.dark),
                color:
                  themeSettings.textColors.productBox ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.productBox.light
                    : defaultTheme.colors.componentText.productBox.dark),
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              cart: (props) => ({
                bg:
                  themeSettings.backgrounds.cartBackground ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.cart.light
                    : defaultTheme.colors.componentBg.cart.dark),
                color:
                  themeSettings.textColors.cart ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.cart.light
                    : defaultTheme.colors.componentText.cart.dark),
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              header: (props) => ({
                bg:
                  themeSettings.backgrounds.header ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.header.light
                    : defaultTheme.colors.componentBg.header.dark),
                color:
                  themeSettings.textColors.header ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.header.light
                    : defaultTheme.colors.componentText.header.dark),
                boxShadow: "sm",
                padding: 4,
              }),
              footer: (props) => ({
                bg:
                  themeSettings.backgrounds.footer ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.footer.light
                    : defaultTheme.colors.componentBg.footer.dark),
                color:
                  themeSettings.textColors.footer ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.footer.light
                    : defaultTheme.colors.componentText.footer.dark),
                boxShadow: "sm",
                padding: 4,
              }),
              mobileFooter: (props) => ({
                bg:
                  themeSettings.backgrounds.footer ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mobileFooter.light
                    : defaultTheme.colors.componentBg.mobileFooter.dark),
                color:
                  themeSettings.textColors.footer ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mobileFooter.light
                    : defaultTheme.colors.componentText.mobileFooter.dark),
                boxShadow: "sm",
                padding: 4,
              }),
              mainPage: (props) => ({
                bg:
                  themeSettings.backgrounds.mainPage ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mainPage.light
                    : defaultTheme.colors.componentBg.mainPage.dark),
                color:
                  themeSettings.textColors.mainPage ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mainPage.light
                    : defaultTheme.colors.componentText.mainPage.dark),
                padding: 4,
              }),
            },
          },
          Modal: {
            variants: {
              CartModal: (props) => ({
                bg:
                  themeSettings.backgrounds.CartModal ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.CartModal.light
                    : defaultTheme.colors.componentBg.CartModal.dark),
                color:
                  themeSettings.textColors.CartModal ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.CartModal.light
                    : defaultTheme.colors.componentText.CartModal.dark),
              }),
              UserModal: (props) => ({
                bg:
                  themeSettings.backgrounds.UserModal ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.UserModal.light
                    : defaultTheme.colors.componentBg.UserModal.dark),
                color:
                  themeSettings.textColors.UserModal ||
                  (props.colorMode === "light"
                    ? defaultTheme.colors.componentText.UserModal.light
                    : defaultTheme.colors.componentText.UserModal.dark),
              }),
            },
          },
          // ... alte componente
        },
      });
      setCustomTheme(updatedTheme); // Actualizează tema personalizată

      // dispatch({ type: "SET_CUSTOM_THEME", payload: updatedTheme });
    }
  };

  const applyConfig = useCallback(
    (appCode, newConfig, serverVersion, language) => {
      // Save configuration and version to localStorage

      setConfigToLocal(appCode, newConfig, serverVersion, language);
      // setLocalStorage(linkState.appCode, `${configKey}-version`, serverVersion);

      dispatch({ type: "SET_CONFIG", payload: newConfig });
      dispatch({ type: "SET_CONFIG_VERSION", payload: serverVersion });

      doApplyConfig(newConfig);
    },
    // [state, dispatch]
    // [setCustomTheme, linkState.appCode]
    [state.appInfo]
  );

  // Callback pentru actualizarea configurației când versiunile diferă
  const onConfigUpdate = useCallback(
    async (appCode, serverVersion) => {
      if (appCode === "noAppCode") return;

      const lang = state.linkState.language || "en";
      // const configKey = `config-${lang}`;
      try {
        const appID = state.appInfo.AppID;
        const newConfig = await downloadNewConfig(appID, lang, state.appInfo.AgencyName);

        applyConfig(appCode, newConfig, serverVersion, lang);
        // Salvează noua configurație în localStorage
        setConfigToLocal(appCode, newConfig, serverVersion, lang);
      } catch (error) {
        console.error("Failed to download config for current language:", error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to update configuration.",
        });
      }
    },
    [state.appInfo, state.linkState.language, applyConfig]
  );

  // Funcția pentru încărcarea configurației locale
  const loadConfigFromLocal = useCallback(
    (localConfig) => {
      dispatch({ type: "SET_CONFIG", payload: localConfig });
      doApplyConfig(localConfig);
    },
    // [setCustomTheme, state]
    [state.appInfo]
  );

  // Callback pentru încărcarea configurației locale când versiunile se potrivesc
  const onConfigMatch = useCallback(
    (localConfig) => {
      loadConfigFromLocal(localConfig);
    },
    [loadConfigFromLocal]
  );

  // Funcție pentru actualizarea configVersionCheckedTime
  const setConfigVersionCheckedTime = (newTime) => {
    dispatch({ type: "SET_CONFIG_VERSION_CHECKED_TIME", payload: newTime });
    // setLocalStorage("configVersionCheckedTime", newTime); // Salvăm în localStorage
  };

  // Utilizează hook-ul pentru verificarea versiunii configurației
  useCheckConfigVersion(
    state.appInfo?.AppCode,
    state.appInfo?.AppID,
    state.linkState.language,
    state.sessionID,
    state.configVersion,
    state.linkState,
    onConfigUpdate,
    onConfigMatch,
    state.configVersionCheckedTime, // configVersionCheckedTime din linkState
    setConfigVersionCheckedTime // funcție pentru a actualiza configVersionCheckedTime
  );

  // const memoizedTheme = useMemo(() => customTheme, [customTheme]);

  // Actualizează coșul când configOBJ se schimbă
  useEffect(() => {
    dispatch({ type: "UPDATE_CART_AFTER_CONFIG" });
  }, [state.configOBJ]);

  useEffect(() => {
    i18n.changeLanguage(state.linkState.language);
  }, [state.linkState.language, i18n]);

  // Logica pentru parsarea URL-ului și actualizarea linkState (link - > linkState)
  useEffect(() => {
    const envConfig = require("../config/config");
    let route = "";
    let language = "en";
    let appCode = "noAppCode";
    let appDomain = "";
    let page = "menu";
    let search = null;
    let category = null;
    let product = null;

    const url = window.location.href;
    const urlObj = new URL(url);

    // console.log(
    //   "set linkstate ================================================location",
    //   location
    // );

    let pathSegments = location.pathname.split("/").filter(Boolean);

    // console.log(
    //   "=============================  old loadingProvider, old setup linkState:",
    //   state.linkState
    // );

    const subDomain = urlObj.hostname.split(".")[0];
    const agencySubDomains = envConfig.agencySubDomains;
    // console.log(
    //   "set linkstate ================================================subDomain",
    //   subDomain
    // );
    // console.log(
    //   "set linkstate ================================================agencySubDomains",
    //   agencySubDomains
    // );
    // console.log(
    //   "set linkstate ================================================ new pathSegments",
    //   pathSegments
    // );

    if (pathSegments[0] === "store") {
      if (agencySubDomains.includes(urlObj.hostname) || isIP(urlObj.hostname)) {
        appCode = pathSegments[1] || null;
      } else {
        appDomain = subDomain; // Actualizează variabila appDomain
      }

      route = "store";
      pathSegments = pathSegments.slice(2); // Evită mutarea directă folosind splice
    } else if (pathSegments[0] === "portal") {
      route = "portal";
      appCode = "portal";
      pathSegments = pathSegments.slice(1); // Evită mutarea directă folosind splice
    }

    // Parsează segmentele căii
    pathSegments.forEach((segment) => {
      if (segment.startsWith("l_")) {
        language = extractParam(segment, "l_") || language;
      } else if (segment.startsWith("p_")) {
        page = extractParam(segment, "p_") || page;
      } else if (segment.startsWith("s_")) {
        search = extractParam(segment, "s_") || search;
      } else if (segment.startsWith("c_")) {
        category = extractParam(segment, "c_") || category;
      } else if (segment.startsWith("pr_")) {
        product = extractParam(segment, "pr_") || product;
      }
    });

    const initialLinkState = {
      route: route || "portal",
      appCode: appCode || "noAppCode",
      language: language || "en",
      page: page || "menu",
      search: search || null,
      category: category || null,
      product: product || null,
      appDomain: appDomain || "",
    };

    // console.log(
    //   "set linkstate ======================== new LinkState",
    //   initialLinkState
    // );

    // Compară linkState curent cu initialLinkState pentru a evita actualizările redundante
    const isLinkStateEqual = Object.keys(initialLinkState).every(
      (key) => state.linkState[key] === initialLinkState[key]
    );
    // console.log(
    //   "========================== isLinkStateEqual ??????????????????????/:",
    //   isLinkStateEqual
    // );
    // dispatch({ type: "SET_LINK_STATE", payload: initialLinkState });
    if (!isLinkStateEqual) {
      dispatch({ type: "SET_LINK_STATE", payload: initialLinkState });

      // console.log(
      //   "======================SAVED      new ====== link state:",
      //   initialLinkState.appCode,
      //   initialLinkState.page,
      //   initialLinkState
      // );
      let newPathSegments = [];
      if (initialLinkState.route === "store") {
        newPathSegments = [
          initialLinkState.route,
          initialLinkState.appCode,
          `l_${initialLinkState.language}`,
          `p_${initialLinkState.page}`,
          initialLinkState.search ? `s_${initialLinkState.search}` : null,
          initialLinkState.category ? `c_${initialLinkState.category}` : null,
          initialLinkState.product ? `pr_${initialLinkState.product}` : null,
        ].filter(Boolean); // Elimină valorile nule
      } else if (initialLinkState.route === "portal") {
        newPathSegments = [initialLinkState.route].filter(Boolean);
      }
      // Construiște noua cale cu prefixuri

      const newPath = `/${newPathSegments.join("/")}`;
      // console.log("AppProvider => initialLinkState", initialLinkState);
      // console.log("AppProvider => isLinkStateEqual", isLinkStateEqual);
      // console.log("AppProvider => newPath", newPath);
      // Dacă calea curentă nu se potrivește cu noua cale și calea nu a fost înlocuită deja
      if (
        newPathSegments.length > 0 &&
        // location.pathname !== newPath &&
        !hasReplacedPathRef.current
      ) {
        // window.history.replaceState(null, "", newPath); // Înlocuiește calea curentă fără a naviga
        navigate(newPath, { replace: true });
        console.log(
          `==============================  Path replaced to: ${newPath}`
        );
        hasReplacedPathRef.current = true; // Setează flag-ul pentru a preveni loop-ul
      }
    }
    // }, [params, state.linkState, dispatch, location.pathname]);
    // }, [location.pathname]);

    // Setăm isReady la true după ce linkState a fost setată
    setIsReady(true);
  }, [location.pathname, state.linkState, dispatch, navigate]);
  // }, [location.pathname]);

  // Resetarea flag-ului după ce navigăm
  useEffect(() => {
    hasReplacedPathRef.current = false;
  }, [location.pathname]);

  // Inițializarea sesiunii
  useEffect(() => {
    const fetchSession = async () => {
      const envConfig = require("../config/config");

      // dispatch({ type: "SET_LOADING_SESSION", payload: true }); // Începe încărcarea sesiunii
      try {
        let storedSessionID = localStorage.getItem("SessionID");

        // getLocalStorage(currentAppCode, "SessionID"); // 'appCode-SessionID'
        if (!storedSessionID) {
          const clientSessionID = uuidv4();

          const newSessionID = await initializeSession(clientSessionID);

          localStorage.setItem("SessionID", newSessionID);
          // setLocalStorage(currentAppCode, "SessionID", newSessionID); // 'appCode-SessionID'
          dispatch({ type: "SET_SESSION_ID", payload: newSessionID });
          console.log("New sessionID initialized:", newSessionID);
        } else {
          dispatch({ type: "SET_SESSION_ID", payload: storedSessionID });
          console.log("Existing sessionID loaded:", storedSessionID);
        }
      } catch (error) {
        console.error("Failed to initialize session, error:", error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to initialize session.",
        });
      }
    };

    fetchSession();
  }, []); // Lista de dependențe goală pentru a preveni apeluri multiple

  // Actualizează tema personalizată când configOBJ.theme se schimbă
  useEffect(() => {
    if (
      state.configOBJ.theme &&
      Object.keys(state.configOBJ.theme).length > 0
    ) {
      const primaryColorString = state.configOBJ.theme.primaryColor;
      const primaryColor = getColorFromTheme(primaryColorString, defaultTheme);

      // Extindem tema cu setările actualizate
      const updatedTheme = extendTheme({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary: primaryColor,
          primaryDark: shade(0.2, primaryColor),
          primaryLight: tint(0.2, primaryColor),
          button: state.configOBJ.theme.buttonColor,
          // ... alte culori
        },
        fonts: state.configOBJ.theme.fonts,
        styles: {
          global: (props) => ({
            body: {
              bg:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentBg.mainPage.light
                  : defaultTheme.colors.componentBg.mainPage.dark,
              color:
                props && props.colorMode === "light"
                  ? defaultTheme.colors.componentText.mainPage.light
                  : defaultTheme.colors.componentText.mainPage.dark,
            },
          }),
        },
        components: {
          Box: {
            variants: {
              productBox: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.productBox.light
                    : defaultTheme.colors.componentBg.productBox.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.productBox.light
                    : defaultTheme.colors.componentText.productBox.dark,
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              cart: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.cart.light
                    : defaultTheme.colors.componentBg.cart.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.cart.light
                    : defaultTheme.colors.componentText.cart.dark,
                borderRadius: "lg",
                boxShadow: "md",
                padding: 4,
              }),
              header: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.header.light
                    : defaultTheme.colors.componentBg.header.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.header.light
                    : defaultTheme.colors.componentText.header.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              footer: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.footer.light
                    : defaultTheme.colors.componentBg.footer.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.footer.light
                    : defaultTheme.colors.componentText.footer.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              mobileFooter: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mobileFooter.light
                    : defaultTheme.colors.componentBg.mobileFooter.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mobileFooter.light
                    : defaultTheme.colors.componentText.mobileFooter.dark,
                boxShadow: "sm",
                padding: 4,
              }),
              mainPage: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.mainPage.light
                    : defaultTheme.colors.componentBg.mainPage.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.mainPage.light
                    : defaultTheme.colors.componentText.mainPage.dark,
                padding: 4,
              }),
            },
          },
          Modal: {
            variants: {
              CartModal: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.CartModal.light
                    : defaultTheme.colors.componentBg.CartModal.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.CartModal.light
                    : defaultTheme.colors.componentText.CartModal.dark,
              }),
              UserModal: (props) => ({
                bg:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentBg.UserModal.light
                    : defaultTheme.colors.componentBg.UserModal.dark,
                color:
                  props.colorMode === "light"
                    ? defaultTheme.colors.componentText.UserModal.light
                    : defaultTheme.colors.componentText.UserModal.dark,
              }),
            },
          },
        },
      });
      setCustomTheme(updatedTheme); // Actualizează tema personalizată
    }
  }, [state.configOBJ.theme]);

  // useEffect(() => {
  //   if (state.configOBJ?.theme) {
  //     console.log(
  //       "configOBJ.theme.backgrounds:",
  //       state.configOBJ.theme.backgrounds
  //     );
  //     // ... restul codului pentru actualizarea temei
  //   }
  // }, [state.configOBJ?.theme]);

  // Gestionarea favoritelelor
  useEffect(() => {
    // Încarcă favoritele la inițializare
    const loadFavorites = () => {
      if (state.user) {
        // Dacă utilizatorul este autentificat, încărcăm favoritele din user object
        dispatch({
          type: "SET_FAVORITES",
          payload: state.user.favorites || [],
        });
      } else {
        // Dacă nu, încărcăm din localStorage
        const storedFavorites =
          getLocalStorage(linkState.appCode, "favorites") || [];
        dispatch({ type: "SET_FAVORITES", payload: storedFavorites });
      }
    };

    loadFavorites();
  }, [state.user, linkState.appCode]);

  useEffect(() => {
    // Salvează favoritele în funcție de autentificare
    if (state.user) {
      // Salvează favoritele în user object (presupunând că ai o funcție pentru asta)
      // saveUserFavorites(state.user.id, state.favorites);
    }
    // Salvează si în localStorage
    setLocalStorage(linkState.appCode, "favorites", state.favorites);
  }, [state.favorites, state.user, linkState.appCode]);

  return (
    // <AppContext.Provider value={{ state, dispatch }}>
    //   <ChakraProvider theme={customTheme}>{children}</ChakraProvider>
    // </AppContext.Provider>

    <AppContext.Provider value={{ state, dispatch }}>
      {isReady ? (
        <ChakraProvider theme={customTheme}>{children}</ChakraProvider>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <LoadingIndicator message="Loading..." />
        </Box>
      )}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
