// src/MainApp.jsx
import React, { useContext, Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import LoadingIndicator from "./components/Loading/LoadingIndicator";
import { Box, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";




const Portal = lazy(() => import("./pages/Portal"));
const Store = lazy(() => import("./pages/Store"));
// const PageSelector = lazy(() => import("./components/Layout/PageSelector"));

const MainApp = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  const isProduction = process.env.NODE_ENV === "production";

  // Gestionarea stării de încărcare
  if (
    state.isLoadingInitializeSession ||
    state.isLoadingGetAppInfo ||
    state.isLoadingGetConfigVersion ||
    state.isLoadingDownloadNewConfig ||
    state.isLoadingGetPortalInfo
  ) {
    let loadingMessage = t("loading.loading");

    if (state.isLoadingInitializeSession) {
      loadingMessage = t("loading.loadingSession");
    } else if (state.isLoadingGetAppInfo || state.isLoadingGetConfigVersion) {
      loadingMessage = t("loading.loadingStore");
    } else if (state.isLoadingGetPortalInfo) {
      loadingMessage = t("loading.loadingPortal");
    } else if (
      state.currentPath?.startsWith("/store") &&
      Object.keys(state.config).length !== 0
    ) {
      loadingMessage = t("loading.buildingStore");
    } else if (
      state.currentPath?.startsWith("/portal") &&
      Object.keys(state.portal).length !== 0
    ) {
      loadingMessage = t("loading.buildingPortal");
    }

    return <LoadingIndicator message={loadingMessage} />;
  }

  // Gestionarea erorilor
  if (state.error && !isProduction) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert status="error">
          <AlertIcon />
          <Text>{state.error}</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator message="Încărcare..." />}>
    <Routes>
      {/* Ruta pentru Portal */}
      <Route path="/portal/*" element={<Portal />} />

      {/* Ruta pentru Store */}
      <Route path="/store/:appCode/*" element={<Store />} />

      {/* Ruta wildcard pentru redirecționări generale */}
      <Route path="*" element={<Navigate to="/portal/" replace />} />
    </Routes>
  </Suspense>
  );
};

export default MainApp;
